import React, { useRef } from "react";
import { navigate, graphql } from "gatsby";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

const BlogPostTemplate = ({ location, data: { previous, next, post } }) => {
  const FeaturedImg = {
    data: post?.featuredImage?.node?.localFile?.childImageSharp
      ?.gatsbyImageData,
    alt: post?.featuredImage?.node?.alt || ``,
  };

  return (
    post && (
      <>
        <Helmet>
          <title>{post.title}</title>
        </Helmet>

        <Layout design="dark">
          <SinglePost>
            <div className="imgWrapper">
              <GatsbyImage
                image={FeaturedImg.data}
                alt={FeaturedImg.alt}
                height={200}
                className="d-block"
              />
              <span className="date">{post.date}</span>
            </div>
            <h1>{post.title}</h1>
            {parse(post.content)}
          </SinglePost>
        </Layout>
      </>
    )
  );
};

export const SinglePost = styled.div`
  padding-top: 18vh;
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 25px;

  .imgWrapper {
    width: 100%;
    box-shadow: -0.707px -0.707px 16px 0px rgba(0, 0, 0, 0.12);
    box-sizing: content-box;
    margin-bottom: 20px;
    .date {
      font-size: 0.7rem;
      font-weight: 500;
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
      padding: 10px;
    }
  }

  @media screen and (min-width: 992px) {
    padding-top: 15%;
    padding-bottom: 5%;
    gap: 50px;
    .imgWrapper {
      max-width: 40%;
      padding-bottom: 12px;
      margin-bottom: 0;
    }
  }
`;

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      language {
        code
      }
      translations {
        uri
        language {
          code
        }
      }
      author {
        node {
          name
        }
      }
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
      language {
        code
      }
      translations {
        uri
        language {
          code
        }
      }
    }
  }
`;

export default BlogPostTemplate;
